const Legend = ({ taskStatus }) => {
  return (
    <div className="bg-gray-50 p-4 rounded-md shadow-sm">
      <h4 className="text-lg font-semibold mb-4 text-gray-700">Status Legend</h4>
      <ul className="space-y-2">
        {Object.entries(taskStatus).map(([key, value], index) => (
          <li key={index} className="flex items-center">
            <span className={`w-4 h-4 mr-2 rounded-full ${value === "success" ? "bg-green-500" : value === "running" ? "bg-yellow-500" : "bg-red-500"}`}></span>
            <span className="text-gray-600">{key}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Legend;